import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { Subscription } from 'rxjs';

import { EnvironmentService } from '@patient-ui/shared-ui/utils';

import { LayoutService } from '../../shared/services/layout.service';

@Component({
  selector: 'patient-ui-public-footer',
  templateUrl: './public-footer.component.html',
  styleUrls: ['./public-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicFooterComponent implements OnInit, OnDestroy {
  active: boolean[] = [false, false, false];
  appYear: number = new Date().getFullYear();

  patientLitholinkUrl = '';
  helpCenterUrl = '';
  patientUserAgreement = '';
  apptSchedulingUrl = '';
  buttonSubscription!: Subscription;
  showAppButtons = true;
  footerSubscription!: Subscription;
  showFooterContent = true;
  footerButtonSubscription!: Subscription;
  showFooterButton = true;
  ondemandLinksSubscription!: Subscription;
  ondemandHeaderSubscription!: Subscription;
  ondemandLinks = false;
  ondemandHeader = false;
  onDemandUrl = '';
  portalLinks = false;
  portalLinksSubscription!: Subscription;

  constructor(
    public authStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private envService: EnvironmentService,
    private layoutService: LayoutService,
    private ref: ChangeDetectorRef
  ) {}

  toggleSection(i: number) {
    this.active[i] = !this.active[i];
  }

  async signIn() {
    await this.oktaAuth.signInWithRedirect({
      originalUri: '/',
    });
  }

  ngOnInit(): void {
    this.helpCenterUrl = `${this.envService.patientUrl}/help-center/home`;
    this.patientUserAgreement = `${this.envService.patientUrl}/user-agreement`;
    this.patientLitholinkUrl = `${this.envService.patientUrl}/litholink`;
    this.apptSchedulingUrl = this.envService.apptSchedulingUrl;
    this.onDemandUrl = this.envService.onDemandUrl;

    this.buttonSubscription = this.layoutService
      .getShowNavbarButton()
      .subscribe((buttonsState) => {
        this.showAppButtons = buttonsState;
      });

    this.footerSubscription = this.layoutService
      .getShowFooterContent()
      .subscribe((footerState) => {
        this.showFooterContent = footerState;
      });

    this.footerButtonSubscription = this.layoutService
      .getShowFooterAppButtons()
      .subscribe((footerButtonState) => {
        this.showFooterButton = footerButtonState;
        this.ref.detectChanges();
      });

    this.ondemandLinksSubscription = this.layoutService
      .getShowOndemandLinks()
      .subscribe((ondemandLinksState) => {
        this.ondemandLinks = ondemandLinksState;
        this.ref.detectChanges();
      });

    this.ondemandHeaderSubscription = this.layoutService
      .getShowOndemandHeader()
      .subscribe((ondemandHeaderState) => {
        this.ondemandHeader = ondemandHeaderState;
        this.ref.detectChanges();
      });

    this.portalLinksSubscription = this.layoutService
      .getShowPortalHeaderLinks()
      .subscribe((patientHeaderState) => {
        this.portalLinks = patientHeaderState;
        this.ref.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.buttonSubscription.unsubscribe();
    this.footerSubscription.unsubscribe();
    this.footerButtonSubscription.unsubscribe();
    this.ondemandLinksSubscription.unsubscribe();
    this.ondemandHeaderSubscription.unsubscribe();
    this.portalLinksSubscription.unsubscribe();
  }
}
