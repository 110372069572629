import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import {
  BootstrapEnvironment,
  Environment,
  IEnvironmentService,
} from '../types/environment.types';

export const BOOTSTRAP_ENVIRONMENT_TOKEN = new InjectionToken<
  BootstrapEnvironment
>('BootstrapEnvironment');

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService implements IEnvironmentService {
  private static _bootstrapEnv: BootstrapEnvironment;
  private env: Environment = {} as Environment;
  readonly APP_ENV_PATH = 'assets/environment.json';
  readonly APP_ENV_VERSION_PATH = 'assets/version.json';

  public env$: BehaviorSubject<Environment | null> = new BehaviorSubject<Environment | null>(
    null
  );

  constructor(
    private http: HttpClient,
    @Inject(BOOTSTRAP_ENVIRONMENT_TOKEN)
    bootstrapEnvironment: BootstrapEnvironment
  ) {
    if (!EnvironmentService._bootstrapEnv) {
      EnvironmentService._bootstrapEnv = bootstrapEnvironment;
    }
  }

  get environment$() {
    return this.env$;
  }

  get features() {
    return this.env.feature;
  }

  get isProduction() {
    return EnvironmentService.isProduction;
  }

  get environmentName() {
    return this.env.name;
  }

  get baseUrl() {
    return this.env.api.baseUrl;
  }

  get aciUrl() {
    return this.env.application.aciUrl;
  }

  get patientUrl() {
    return this.env.application.patientUrl;
  }

  get expressUrl() {
    return this.env.application.expressUrl;
  }

  get preCheckUrl() {
    return this.env.application.preCheckUrl;
  }

  get apptSchedulingUrl() {
    return this.env.application.apptSchedulingUrl;
  }

  get onDemandUrl() {
    return this.env.application.onDemandUrl;
  }

  get smartyStreetsBaseUrl() {
    return this.env.api.smartyStreetsBaseUrl;
  }

  get smartyStreetsSiteKey() {
    return this.env.api.smartyStreetsSiteKey;
  }

  get paymentContextUrl() {
    return this.env.api.paymentContextUrl;
  }

  get paymentCallbackUrl() {
    return this.env.api.paymentCallbackUrl;
  }

  get paymentSource() {
    return this.env.api.paymentSource;
  }

  get recaptchaKey() {
    return this.env.google.recaptchaKey;
  }

  get oktaIssuer() {
    return this.env.okta.issuer;
  }

  get oktaClientId() {
    return this.env.okta.clientId;
  }

  get oktaRedirectUri() {
    return this.env.okta.redirectUri;
  }

  get ondemandClientId() {
    return this.env.okta.ondemandClientID;
  }

  get adobeScript() {
    return this.env.scripts.adobe;
  }

  get appVersion() {
    return this.env.version || '';
  }

  async loadEnvironment() {
    if (!this.http) {
      console.error('HttpClient not provided. Environment loading skipped.');
      return;
    }

    const runtimeEnv = await combineLatest([
      this.http.get<Environment>(this.APP_ENV_PATH),
      this.http.get<Environment>(this.APP_ENV_VERSION_PATH),
    ])
      .pipe(
        map(
          ([appEnvRes, appEnvVerRes]) =>
            ({
              ...appEnvRes,
              ...appEnvVerRes,
            } as Environment)
        )
      )
      .toPromise();
    this.env = runtimeEnv;
    const script = document.createElement('script');
    script.defer = true;
    script.src = this.adobeScript;
    document.head.appendChild(script);
  }

  loadEnvironmentOkta() {
    return combineLatest([
      this.http.get<Environment>('assets/environment.json'),
      this.http.get<Environment>('assets/version.json'),
    ])
      .pipe(
        map(
          ([appEnv, appEnvVer]) => ({ ...appEnv, ...appEnvVer } as Environment)
        ),
        shareReplay(1)
      )
      .toPromise();
  }

  static get isProduction() {
    return !EnvironmentService._bootstrapEnv.isDebugEnabled;
  }
}
